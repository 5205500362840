import { animateScroll } from 'react-scroll';
import AdditionalInformationTab from '../component/AdditionalInformationTab';
import { trimCustomerAddress } from 'SourceUtil/Address';

const ADDITIONAL_INFORMATION = 'additional_information';

const renderAdditionalInformationTab = (key, props) => {
    return <AdditionalInformationTab key={key} {...props} />
}

const addToProductTab = (member, instance) => {
    return {
        ...member,
        [ADDITIONAL_INFORMATION]: {
            name: __("additional information"),
            shouldTabRender: () => true,
            render: (key) => renderAdditionalInformationTab(key, instance.props)
        }
    }
}

export default {
    'Route/ProductPage/Component': {
        'member-property': {
            'tabMap': {
                implementation: addToProductTab,
                position: 0
            }
        }
    }
}
