// import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './AdditionalInformationTab.style';

/** @namespace Hoeks/Gpsr/Component/AdditionalInformationTab/Component/AdditionalInformationTabComponent */
export class AdditionalInformationTabComponent extends PureComponent {
    render() {
        const {
            product: {
                manufacture = '',
                manufacture_address = '',
                manufacture_email = '',
                manufacture_product_owner = ''
            } = {}
        } = this.props;

        if (manufacture || manufacture_address || manufacture_email || manufacture_product_owner) {
            return (
                <div block="AdditionalInformationTab">
                    <dl block="AdditionalInformationTab" elem="List">
                        {manufacture ?<div block="AdditionalInformation" elem="Attribute">
                            <dt block="AdditionalInformation" elem="Title">
                                {__('Manufacture')}:
                            </dt>
                            <dd block="AdditionalInformation" elem="Item">
                                <div block="AdditionalInformation" elem="Value">{manufacture}</div>
                            </dd>
                        </div> : <></>}

                        {manufacture_address ? <div block="AdditionalInformation" elem="Attribute">
                            <dt block="AdditionalInformation" elem="Title">
                                {__('Manufacture Address')}:
                            </dt>
                            <dd block="AdditionalInformation" elem="Item">
                                <div block="AdditionalInformation" elem="Value">{manufacture_address}</div>
                            </dd>
                        </div> : <></>}

                        {manufacture_email ? <div block="AdditionalInformation" elem="Attribute">
                            <dt block="AdditionalInformation" elem="Title">
                                {__('Manufacture E-mail')}:
                            </dt>
                            <dd block="AdditionalInformation" elem="Item">
                                <div block="AdditionalInformation" elem="Value">{manufacture_email}</div>
                            </dd>
                        </div> : <></>}

                        {manufacture_product_owner ? <div block="AdditionalInformation" elem="Attribute">
                            <dt block="AdditionalInformation" elem="Title">
                                {__('Manufacture Product owner')}:
                            </dt>
                            <dd block="AdditionalInformation" elem="Item">
                                <div block="AdditionalInformation" elem="Value">{manufacture_product_owner}</div>
                            </dd>
                        </div> : <></>}
                    </dl>
                </div>
            );
        } else {
            return (
                <div block="AdditionalInformationTab">
                    <p>{__('There are no additional information for this product')}</p>
                </div>
            );
        }

    }
}

export default AdditionalInformationTabComponent;
