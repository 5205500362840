// import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import AdditionalInformationTab from './AdditionalInformationTab.component';

/** @namespace Hoeks/Gpsr/Component/AdditionalInformationTab/Container/AdditionalInformationTabContainer */
export class AdditionalInformationTabContainer extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    containerFunctions = {
        // getData: this.getData.bind(this)
    };

    containerProps = () => {
        // isDisabled: this._getIsDisabled()
    };

    render() {
        return (
            <AdditionalInformationTab
              { ...this.containerFunctions }
              { ...this.containerProps() }
              { ... this.props }
            />
        );
    }
}

export default AdditionalInformationTabContainer;
