/* eslint-disable */

import { ProductListQuery as SourceProductListQuery } from 'HoeksQuery/ProductList.query';
import { CUSTOMER } from 'Store/MyAccount/MyAccount.dispatcher';
import BrowserDatabase from 'Util/BrowserDatabase';
export * from 'HoeksQuery/ProductList.query';

/**
 * Product List Query
 * @class ProductListQuery
 * @namespace Query/ProductList
 */
export class ProductListQuery extends SourceProductListQuery {
    _getProductInterfaceFields(isVariant, isForLinkedProducts = false) {
        return [
            ...super._getProductInterfaceFields(isVariant, isForLinkedProducts),
            'purchase_by_contact',
            'saerligt_god_pris',
            'variant_badge',
            'leveringtid_ny',
            'badge_new',
            'badge_limited_edition',
            'vigtig_besked',
            'manufacture',
            'manufacture_address',
            'manufacture_email',
            'manufacture_product_owner'
        ];
    }

    _getAttributeFields(isVariant) {
        return [
            'attribute_id',
            'attribute_value',
            'attribute_code',
            'attribute_type',
            'attribute_label',
            'attribute_group_id',
            'attribute_group_code',
            'attribute_group_name',
            this._getAttributeOptionsField()
        ];
    }

    _getArgumentsMap() {
        const filterArgumentMap = this._getFilterArgumentMap();
        const result = super._getArgumentsMap();
        const {
            filter: {
                type
            } = {}
        } = result;

        return {
            ...result,
            filter: {
                type,
                handler: (initialOptions = {}) => {
                    // add customer group by default to all requests
                    const { group_id } = BrowserDatabase.getItem(CUSTOMER) || {};

                    const options = {
                        ...initialOptions,
                        customerGroupId: group_id || '0'
                    };

                    const {
                        customFilters: { category_id } = {}
                    } = options;

                    /**
                     * Remove category ID from select, if there is a custom filter
                     * of category already selected in filtering options.
                     */

                    const originalCategoryId = category_id ? options.categoryIds : null;

                    if (category_id) {
                        // eslint-disable-next-line fp/no-delete
                        options.categoryIds = undefined;
                    }

                    const parsedOptions = Object.entries(options).reduce(
                        (acc, [key, option]) => {
                            // if there is no value, or if the key is just not present in options object
                            if (!option || !filterArgumentMap[key]) {
                                return acc;
                            }

                            return { ...acc, ...filterArgumentMap[key](option) };
                        },
                        {}
                    );

                    if (originalCategoryId) {
                        const {
                            category_id: {
                                in: ids
                            } = {}
                        } = parsedOptions;
                        parsedOptions.category_id.in = [...ids, originalCategoryId.toString()];
                    }

                    return parsedOptions;
                }
            }
        }
    }
}

export default new ProductListQuery();
